import React from "react";
import "../assets/css/style.css";

import Header from "../components/Header";

import analytics from "../assets/img/analytics_undraw.svg";
import staking from "../assets/img/staking_undraw.svg";
import funds from "../assets/img/funds_undraw.svg";

class Home extends React.Component {
  render() {
    return(
      <div className="div-home container">
        <Header />
        <div className="row">
          <div className="col-md-8">
            {
              this.props.page === "funds" ? 
                <>
                <h1>Blockchain focused<span className="font-gold">.</span> <br/> Web3, DeFi<span className="font-gold">.</span> <br/> Gaming, DAO<span className="font-gold">.</span> <br/> Layer 2, anything<span className="font-gold">.</span></h1>
                <a className="btn btn-primary rounded-pill" href="#contact">Contact Us</a>
                </>
              : this.props.page === "staking"?
                <>
                <h1>Hold your asset<span className="font-gold">.</span> <br/> Delegate<span className="font-gold">.</span> <br/> And get more<span className="font-gold">.</span></h1>
                <a className="btn btn-gold rounded-pill" href="/stake#networks">Supported Networks</a>
                <button className="btn btn-primary rounded-pill mx-4">Contact Us</button>
                </>
              : <>
              <h1>Grow blockchain project<span className="font-gold">.</span> <br/>Run, Participate<span className="font-gold">.</span> <br/> Validating<span className="font-gold">.</span> <br/> And secure it<span className="font-gold">.</span></h1>
              <a className="btn btn-gold rounded-pill" href="/#products">Our Products</a>
              <button className="btn btn-primary rounded-pill mx-4">Contact Us</button>
              </>
            }
          </div>
          <div className="col-md-4">
            <img src={this.props.page === "funds" ? funds : this.props.page === "staking" ? staking : analytics } alt="Analytics Dashboard" className="vector_home" />
          </div>
        </div>
        {/* <div className="row summary">
          {
            this.props.page === "staking" ?
              <>
              <div className="col-md-6 col-6">
                <p>Networks</p>
                <span>10</span>
              </div>
              <div className="col-md-6 col-6">
                <p>Value Staked</p>
                <span><span className="font-gold">$</span> 1,000,000,000</span>
              </div>
              </>
            : this.props.page === "funds" ?
              <>
              <div className="col-md-12 col-12">
                <p>Projects Invested</p>
                <span>26+</span>
              </div>
              </>
            : <>
            <div className="col-md-4 col-6">
              <p>Products</p>
              <span>10</span>
            </div>
            <div className="col-md-4 col-6">
              <p>Networks</p>
              <span>10</span>
            </div>
            <div className="col-md-4 col-12">
              <p>Assets Staked</p>
              <span><span className="font-gold">$</span> 1,000,000,000</span>
            </div>
            </>
          }
        </div> */}
      </div>
    )
  }
}

export default Home;