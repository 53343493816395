import React from "react";

class Contact extends React.Component {
  render() {
    return(
      <div className="div-primary div-contact" id="contact">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-4">
              <h2>Need more information<span className="font-gold">?</span> <br/> Let's talk about the future<span className="font-gold">.</span></h2>
            </div>
            <div className="col-lg-7 col-md-8 pt-4">
              <div className="row">
                <div className="col-3 text-center">
                  <a href={this.props.page === "funds" ? "mailto:funds@thecodes.dev" : "mailto:contribute@thecodes.dev"} target="_blank" rel="noreferrer">
                    <div>
                      <i className="bi bi-envelope"></i>
                    </div>
                    <p>Email</p>
                  </a>
                </div>
                <div className="col-3 text-center">
                  <a href="https://t.me/thecodescommunity" target="_blank" rel="noreferrer">
                    <div>
                      <i className="bi bi-telegram"></i>
                    </div>
                    <p>Telegram</p>
                  </a>
                </div>
                <div className="col-3 text-center">
                  <a href="https://facebook.com/groups/Pemburu.Bitcoin.Indonesia" target="_blank" rel="noreferrer">
                    <div>
                      <i className="bi bi-facebook"></i>
                    </div>
                    <p>Facebook</p>
                  </a>
                </div>
                <div className="col-3 text-center">
                  <a href={this.props.page === "funds" ? "https://twitter.com/thecodesfunds" : "https://twitter.com/thecodesdev"} target="_blank" rel="noreferrer">
                    <div>
                      <i className="bi bi-twitter"></i>
                    </div>
                    <p>Twitter</p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Contact;