import React from "react";

import teamwork from "../assets/img/teamwork_undraw.svg";

import mina from "../assets/img/mina.jpeg";
import harmony from "../assets/img/harmony.jpeg";
import meter from "../assets/img/meter.jpeg";
import akash from "../assets/img/akash.jpeg";
import bluzelle from "../assets/img/bluzelle.jpeg";
import polygon from "../assets/img/polygon.png";
import elrond from "../assets/img/elrond.png";
import avalanche from "../assets/img/avalanche.png";

class Why extends React.Component {
  render() {
    return(
      <div className="div-primary div-why">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-8">
              {
                this.props.page === "funds" ?
                  <>
                  <h2 className="mt-5">We have developer<span className="font-gold">,</span> <br/> node operators<span className="font-gold">,</span> <br/> and many KOLs are connected <br/> to support your project<span className="font-gold">.</span></h2>
                  <p className="mt-4">Our team consists of skillful developers as well as node operators to support your project in terms of development. However, we also have connections with various KOLs and projects from various countries and segments to support your project in terms of marketing.</p>
                  </>
                : <>
                  <h2 className="mt-5">We have experienced in <br/> node operating <br/> and web development<span className="font-gold">.</span></h2>
                  <p className="mt-4">Our team has started working on the operation of nodes on the blockchain from 2019. Starting with various well-known blockchain projects and contributing to their chains.</p>
                  <div className="previous-projects mb-5">
                    <div className="early-div">
                      <img src={harmony} alt="Harmony Protocol" />
                    </div>
                    <div>
                      <img src={elrond} alt="Elrond Network" />
                    </div>
                    <div>
                      <img src={polygon} alt="Polygon" />
                    </div>
                    <div>
                      <img src={meter} alt="Meter" />
                    </div>
                    <div>
                      <img src={mina} alt="Mina Protocol" />
                    </div>
                    <div>
                      <img src={akash} alt="Akash Network" />
                    </div>
                    <div>
                      <img src={avalanche} alt="Avalanche Network" />
                    </div>
                    <div>
                      <img src={bluzelle} alt="Bluzelle" />
                    </div>
                  </div>
                </>
              }
            </div>
            <div className="col-lg-7 vector">
              <img src={teamwork} alt="Vector Us" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Why;