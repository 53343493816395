import React from "react";
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import thecodes from "../assets/img/thecodes.png";

class Header extends React.Component {
  render() {
    return(
      <Navbar expand="lg">
        <Container>
          <Navbar.Brand href="/">
            <img
              src={thecodes}
              width="150"
              height="auto"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="bi bi-text-left"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="justify-content-end" style={{ width: "100%" }}>
              <Nav.Link className="mx-4" href="/#products">Our Products</Nav.Link>
              <Nav.Link className="mx-4" href="/stake">Stake Your Assets</Nav.Link>
              <Nav.Link className="mx-4" href="/funds">Funds</Nav.Link>
              <a href="#contact" className="btn btn-sm btn-primary bold rounded-pill shadow">Contact Us</a>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}

export default Header;