import React from "react";
import './App.css';
import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css";
import "./assets/css/style.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./components/Home";
import Why from "./components/Why";
import Product from "./components/Product";
import Solution from "./components/Solution";
import Contact from "./components/Contact";
import Footer from "./components/Footer";

class App extends React.Component {
  render(){
    return(
      <BrowserRouter>
          <Routes>
            <Route path="/" element={
              <>
              <Home />
              <Why />
              <Product />
              <Solution />
              <Contact />
              </>
            } />
            <Route path="/funds" element={
              <>
              <Home page="funds" />
              <Why page="funds"/>
              <Product page="funds" />
              <Contact page="funds" />
              </>
            } />
            <Route path="/stake" element={
              <>
              <Home page="staking" />
              <Why page="staking"/>
              <Product page="staking" />
              <Contact page="staking" />
              </>
            } />
          </Routes>
          <Footer />
      </BrowserRouter>
    )
  }
}

export default App;