import React from "react";

import thecodes from "../assets/img/thecodes_white.png";

class Footer extends React.Component {
  render() {
    return(
      <div className="div-light div-footer" id="products">
        <div className="container">
          <div className="row">
            <div className="col-md-3 logo">
              <img src={thecodes} alt="TheCodes Logo" />
              <span>Copyright &copy; 2022 TheCodes. </span>
              <span className="d-block mt-1"><a href="/funds" className="d-inline"><span>Funds</span></a> <i className="bi bi-three-dots-vertical"></i> <a href="/stake" className="d-inline"><span>Stake</span></a></span>
            </div>
            <div className="col-md-3">
              <h5>Products</h5>
              <a href="https://umee.thecodes.dev" target="_blank" rel="noreferrer">Umee Explorer</a>
              <a href="https://gogscan.com" target="_blank" rel="noreferrer">GoG Scan</a>
              <a href="https://immutable.thecodes.dev" target="_blank" rel="noreferrer">Immutable Explorer</a>
              <a href="https://persistence.thecodes.dev" target="_blank" rel="noreferrer">Persistence Explorer</a>
              <a href="https://ki.thecodes.dev" target="_blank" rel="noreferrer">Ki Chain Explorer</a>
              <a href="https://rizon.thecodes.dev" target="_blank" rel="noreferrer">Rizon Explorer</a>
              <a href="https://ibcscan.net" target="_blank" rel="noreferrer">IBC Scan</a>
            </div>
            <div className="col-md-3">
              <h5>Networks</h5>
              <a href="https://umee.cc" target="_blank" rel="noreferrer">Umee</a>
              <a href="https://persistence.one" target="_blank" rel="noreferrer">Persistence</a>
              <a href="https://ki.foundation" target="_blank" rel="noreferrer">Ki Chain</a>
              <a href="https://rizon.world/" target="_blank" rel="noreferrer">Rizon</a>
            </div>
            <div className="col-md-3">
              <h5>Socials</h5>
              <a href="https://t.me/thecodescommunity" target="_blank" rel="noreferrer">Telegram</a>
              <a href="https://twitter.com/thecodesdev" target="_blank" rel="noreferrer">Twitter</a>
              <a href="https://facebook.com/groups/Pemburu.Bitcoin.Indonesia" target="_blank" rel="noreferrer">Facebook</a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;