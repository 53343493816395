import React from "react";

import umee from "../assets/img/products/umee.png";
import ki from "../assets/img/products/ki.png";
import gog from "../assets/img/products/gog.jpeg";
import immutable from "../assets/img/products/immutable.jpeg";
import persistence from "../assets/img/products/persistence.jpeg";
import rizon from "../assets/img/products/rizon.jpeg";
import cosmos from "../assets/img/products/cosmos.jpeg";
import odin from "../assets/img/odin.jpeg";
import sr from "../assets/img/stakingrewards.jpeg";

import solana from "../assets/img/portfolio/solana.jpeg";
import avax from "../assets/img/portfolio/avax.jpeg";
import grt from "../assets/img/portfolio/grt.jpeg";
import flow from "../assets/img/portfolio/flow.jpeg";
import mina from "../assets/img/portfolio/mina.png";
import glmr from "../assets/img/portfolio/glmr.jpeg";
import aca from "../assets/img/portfolio/aca.jpeg";
import solcial from "../assets/img/portfolio/solcial.jpeg";
import loa from "../assets/img/portfolio/loa.jpeg";
import real from "../assets/img/portfolio/real.jpeg";
import cherry from "../assets/img/portfolio/cherry.jpeg";
import dor from "../assets/img/portfolio/dor.jpeg";
import dehr from "../assets/img/portfolio/dehr.jpeg";
import goe from "../assets/img/portfolio/goe.jpeg";
import gf from "../assets/img/portfolio/gf.jpeg";
import mcrt from "../assets/img/portfolio/mcrt.jpeg";
import defi from "../assets/img/portfolio/defi.jpeg";
import lum from "../assets/img/portfolio/lum.jpeg";
import fiveire from "../assets/img/portfolio/5ire.jpeg";
import portal from "../assets/img/portfolio/portal.jpeg";
import mit from "../assets/img/portfolio/mit.jpeg";
import voice from "../assets/img/portfolio/voice.jpeg";
import plutonians from "../assets/img/portfolio/plutonians.jpeg";
import fpad from "../assets/img/portfolio/fpad.jpeg";
import dropp from "../assets/img/portfolio/dropp.jpeg";
import corite from "../assets/img/portfolio/corite.jpeg";
import port from "../assets/img/portfolio/port.png";
import tkb from "../assets/img/portfolio/tkb.png";
import fota from "../assets/img/portfolio/fota.png";

class Product extends React.Component {
  render() {
    return(
      <div className="div-light" id="products">
        <div className="container">
          {
            this.props.page === "funds" ?
              <>
              <h1 className="mb-4">Our Portfolio<span className="font-gold">.</span></h1>
              <div className="row">
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://solana.com" target="_blank" rel="noreferrer">
                    <img src={solana} alt="TheCodes Portfolio" />
                    <p>Solana</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://avax.network" target="_blank" rel="noreferrer">
                    <img src={avax} alt="TheCodes Portfolio" />
                    <p>Avalanche</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://thegraph.com" target="_blank" rel="noreferrer">
                    <img src={grt} alt="TheCodes Portfolio" />
                    <p>The Graph</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://flow.com" target="_blank" rel="noreferrer">
                    <img src={flow} alt="TheCodes Portfolio" />
                    <p>Flow</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://minaprotocol.com" target="_blank" rel="noreferrer">
                    <img src={mina} alt="TheCodes Portfolio" />
                    <p>Mina</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://moonbeam.network" target="_blank" rel="noreferrer">
                    <img src={glmr} alt="TheCodes Portfolio" />
                    <p>Moonbeam</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://acala.network" target="_blank" rel="noreferrer">
                    <img src={aca} alt="TheCodes Portfolio" />
                    <p>Acala</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://solcial.io" target="_blank" rel="noreferrer">
                    <img src={solcial} alt="TheCodes Portfolio" />
                    <p>Solcial</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://leagueofancients.com/" target="_blank" rel="noreferrer">
                    <img src={loa} alt="TheCodes Portfolio" />
                    <p>League of Ancients</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://realrealm.io" target="_blank" rel="noreferrer">
                    <img src={real} alt="TheCodes Portfolio" />
                    <p>Real Realm</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://thekillboxgame.com" target="_blank" rel="noreferrer">
                    <img src={tkb} alt="TheCodes Portfolio" />
                    <p>The Kill Box</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://cherry.network" target="_blank" rel="noreferrer">
                    <img src={cherry} alt="TheCodes Portfolio" />
                    <p>Cherry Network</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://doragonland.io" target="_blank" rel="noreferrer">
                    <img src={dor} alt="TheCodes Portfolio" />
                    <p>Doragonland</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://dehr.network" target="_blank" rel="noreferrer">
                    <img src={dehr} alt="TheCodes Portfolio" />
                    <p>DEHR</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://goe.gg" target="_blank" rel="noreferrer">
                    <img src={goe} alt="TheCodes Portfolio" />
                    <p>Aether Games</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://guildfi.com" target="_blank" rel="noreferrer">
                    <img src={gf} alt="TheCodes Portfolio" />
                    <p>Guildfi</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://magiccraft.io" target="_blank" rel="noreferrer">
                    <img src={mcrt} alt="TheCodes Portfolio" />
                    <p>Magic Craft</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://defiyield.app" target="_blank" rel="noreferrer">
                    <img src={defi} alt="TheCodes Portfolio" />
                    <p>DefiYield</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://lum.network" target="_blank" rel="noreferrer">
                    <img src={lum} alt="TheCodes Portfolio" />
                    <p>Lum</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://5ire.org" target="_blank" rel="noreferrer">
                    <img src={fiveire} alt="TheCodes Portfolio" />
                    <p>5ireChain</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://portaldefi.com" target="_blank" rel="noreferrer">
                    <img src={portal} alt="TheCodes Portfolio" />
                    <p>Portal</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://galaxyblitz.world" target="_blank" rel="noreferrer">
                    <img src={mit} alt="TheCodes Portfolio" />
                    <p>GalaxyBlitx</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://voicestreet.org" target="_blank" rel="noreferrer">
                    <img src={voice} alt="TheCodes Portfolio" />
                    <p>Voice Street</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://fota.io" target="_blank" rel="noreferrer">
                    <img src={fota} alt="TheCodes Portfolio" />
                    <p>Fota</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://plutonians.tech" target="_blank" rel="noreferrer">
                    <img src={plutonians} alt="TheCodes Portfolio" />
                    <p>Plutonians</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://fantompad.net" target="_blank" rel="noreferrer">
                    <img src={fpad} alt="TheCodes Portfolio" />
                    <p>Fantompad</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://chainport.io" target="_blank" rel="noreferrer">
                    <img src={port} alt="TheCodes Portfolio" />
                    <p>Chainport</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://dropp.gg" target="_blank" rel="noreferrer">
                    <img src={dropp} alt="TheCodes Portfolio" />
                    <p>DROPP</p>
                  </a>
                </div>
                <div className="col-xl-1 col-md-1 col-3">
                  <a className="div-card2" href="https://corite.com" target="_blank" rel="noreferrer">
                    <img src={corite} alt="TheCodes Portfolio" />
                    <p>Corite</p>
                  </a>
                </div>
              </div>
              </>
            : this.props.page === "staking" ?
            <>
            <h1 className="mb-4" id="networks">Supported Networks<span className="font-gold">.</span></h1>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://persistence.thecodes.dev/validator/kivaloper1hrrsxm9xkuksdcsz7yjw88uxnmdqvdsrmxjfjp" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={ki} alt="Ki Chain" />
                    </div>
                    <div className="div-text">
                      <p>Ki Chain</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://persistence.thecodes.dev/validator/rizonvaloper148u69yh5wq99ll9xnzl577hs3eyfh3f7sc0me4" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={rizon} alt="Rizon" />
                    </div>
                    <div className="div-text">
                      <p>Rizon</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://persistence.thecodes.dev/validator/persistencevaloper1lmu3svflejvs4kpvgqy03kd3jr9skfwk5hjhtg" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={persistence} alt="Persistence" />
                    </div>
                    <div className="div-text">
                      <p>Persistence</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://umee.thecodes.dev/validator/umeevaloper148u69yh5wq99ll9xnzl577hs3eyfh3f7jntmz9" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={umee} alt="Umee" />
                    </div>
                    <div className="div-text">
                      <p>Umee</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://odin.thecodes.dev/validator/odinvaloper1hrrsxm9xkuksdcsz7yjw88uxnmdqvdsrh3lxs4" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={odin} alt="Odin Protocol" />
                    </div>
                    <div className="div-text">
                      <p>ODIN Protocol</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card bg-secondary" href="https://www.stakingrewards.com/savings/thecodes/" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={sr} alt="Staking Rewards" />
                    </div>
                    <div className="div-text">
                      <p>On Staking Rewards</p>
                    </div>
                  </a>
                </div>
              </div>
            </>
            : <>
              <h1>Our Products<span className="font-gold">.</span></h1>
              <div className="row">
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://umee.thecodes.dev" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={umee} alt="Umee" />
                    </div>
                    <div className="div-text">
                      <p>Umee Explorer</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://gogscan.com" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={gog} alt="Guild of Guardians" />
                    </div>
                    <div className="div-text">
                      <p>GoG Scan</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://immutable.thecodes.dev" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={immutable} alt="Immutable X" />
                    </div>
                    <div className="div-text">
                      <p>Immutable X Explorer</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://immutable.thecodes.dev" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={persistence} alt="Persistence" />
                    </div>
                    <div className="div-text">
                      <p>Persistence Explorer</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://rizon.thecodes.dev" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={rizon} alt="Rizon" />
                    </div>
                    <div className="div-text">
                      <p>Rizon Explorer</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://ki.thecodes.dev" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={ki} alt="Ki Chain" />
                    </div>
                    <div className="div-text">
                      <p>Ki Chain Explorer</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://ibcscan.net" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={cosmos} alt="IBC" />
                    </div>
                    <div className="div-text">
                      <p>IBC Explorer</p>
                    </div>
                  </a>
                </div>
                <div className="col-xl-3 col-lg-4 col-md-6">
                  <a className="div-card" href="https://ibcscan.net" target="_blank" rel="noreferrer">
                    <div className="div-image">
                      <img src={odin} alt="Odin Protocol" />
                    </div>
                    <div className="div-text">
                      <p>ODIN Explorer</p>
                    </div>
                  </a>
                </div>
              </div>
            </>
          }
        </div>
      </div>
    )
  }
}

export default Product;